import { graphql } from "gatsby"
import React from "react"
import Masonry from "react-masonry-css"
import { SRLWrapper } from "simple-react-lightbox"
import Banner from "../../components/Banner"
import Layout from "../../components/core/Layout"
import SEO from "../../components/core/SEO"
import Images from "../../components/Images"

type TwoGetherGalleryProps = {
  data: {
    images: {
      edges: Array<{ node: { childImageSharp: any } }>
    }
  }
}

const TwoGetherPage: React.FC<TwoGetherGalleryProps> = ({ data }) => (
  <Layout activePage="projects">
    <SEO title="2-gether Project" />
    <Banner>
      <p>
        <b>This project is now completed.</b>
        <br />
        Thanks to the Arts Council for all of their support and for funding this
        project.
        {/* <br /> */}
        {/* <span className="timescale"> */}
        {/* This project ran from March 2021 to October 2021. */}
        {/* </span> */}
      </p>
    </Banner>
    <h1 className="page-title">2-Gether</h1>
    <div className="community-clay__container">
      <div>
        <p>
          2-Gether was an Arts Council and National Lottery funded project that
          was aimed specifically at people who have required support from a
          carer or loved one and have been isolated by COVID-19 restrictions.
          The participants collaborated with a significant other to create a
          three-dimensional tile that reflected coping strategies during this
          difficult time. The project enabled the participants to express and
          share their experience of living through COVID-19.
        </p>
        <p>
          After the artwork has been exhibited, we hope to find a permanent home
          in the local area leading to community ownership of this art work. The
          tiles were collated to form a large mural which can be seen in the
          images reflecting participants shared experience and reduced their
          feelings of isolation by bringing them together in this collective
          artwork representing the community.
        </p>
        <SRLWrapper>
          <Masonry
            className="masonry-grid"
            columnClassName="masonry-grid__column"
            breakpointCols={4}
          >
            {data.images.edges.map(({ node }) => (
              <img
                style={{ padding: 10, cursor: "pointer", borderRadius: 20 }}
                srcSet={node.childImageSharp.full.srcSet}
                src={node.childImageSharp.full.src}
              />
            ))}
          </Masonry>
        </SRLWrapper>
      </div>
      <SRLWrapper>
        <Images image="2getherlarge" />
      </SRLWrapper>
    </div>
  </Layout>
)

export const query = graphql`query ImagesForTwoGetherGallery {
  images: allFile(
    filter: {relativeDirectory: {eq: "projects/2-gether/closer-images"}}
    sort: {fields: name}
  ) {
    edges {
      node {
        childImageSharp {
          thumb: gatsbyImageData(
            width: 400
            height: 400
            quality: 80
            layout: CONSTRAINED
          )
          full: fluid(maxWidth: 2048) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`

export default TwoGetherPage
